/* eslint-disable complexity */
import React from 'react';
import {
    FlexColumns, Input, useCrudFormRequests, useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { GirlProfileDto } from '@lovelyvoyager/types/dist/girl/girl-profile.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId?: number;
    close?: () => void;
    t: TFunction;
};

export const GirlProfileModal: React.FC<Props> = ({ recordId, close, t }) => {
    const { girls } = useApi();

    const { loading, record, error } = useGetOne(recordId, girls.getOneGirl);

    const {
        updating,
        save,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: girls.changeProfile,
        close,
    });

    const title = record.name;

    return (
        <RestForm
            recordId={ recordId }
            title={ title }
            initialValues={ serializeRecord(GirlProfileDto, { ...record }) }
            validate={ validateByDto(t) }
            close={ close }
            save={ save }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            t={ t }
        >
            { ({ values, handleChange }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label={ t('profileId') }
                            value={ values.profileId ? values.profileId.toString() : '' }
                            onChange={ handleChange('profileId') }
                            type="number"
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
