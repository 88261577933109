import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@alphakits/ui/dist';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';

import { ReviewsColumns } from './columns';

export const ReviewsPage: React.FC = () => {
    const { reviews } = useApi();

    const { t } = useTranslation();

    const columns = React.useMemo(() => ReviewsColumns(t), [t]);

    return (
        <Table.TableComponent
            tagsBuilder={ mapper(t) }
            searchPlaceholder={ t('tables.reviewsSearch') }
            columns={ columns }
            promise={ reviews.queryForAdmin }
            language="ru"
        />
    );
};
