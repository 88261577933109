import { TableColumns } from '@alphakits/ui/dist/table/types';
import { EmailRO } from '@lovelyvoyager/types/dist/emails/email.ro';

export const Columns = (t): TableColumns<EmailRO> => [
    {
        header: t('createdAt'),
        accessor: 'createdAt',
    },
    {
        header: t('profileId'),
        accessor: 'profileId',
    },
    {
        header: t('email'),
        accessor: 'to',
    },
    {
        header: t('delivered'),
        accessor: 'delivered',
    },
];
