import React from 'react';
import { FormattedDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileResponseObject } from '@lovelyvoyager/types/dist/profile/profile.ro';
import { LoginAsUser } from 'src/components/login-as-user';

export const сolumns = (): TableColumns<ProfileResponseObject> => [
    {
        header: 'ID',
        accessor: 'id',
        sort: true,
    },
    {
        header: 'Contacts',
        accessor: 'phone',
    },
    {
        header: 'Accounts',
        accessor: 'girlsCount',
        sort: true,
    },
    {
        header: 'Saloons',
        accessor: 'saloonsCount',
        sort: true,
    },
    {
        header: 'Balance',
        accessor: 'balance',
        sort: true,
    },
    {
        header: 'Last visit',
        accessor: 'lastAuth',
        sort: true,
        row: {
            title: ({ row }) => (
                <FormattedDate weight="medium" date={ row.lastAuth } format="dd.MM.yy, HH:mm" />
            ),
        },
    },
    {
        header: 'Profile',
        accessor: 'profileId',
        row: {
            title: ({ row }) => <LoginAsUser profileId={ row.id } />,
        },
    },
];
