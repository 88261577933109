import React from 'react';
import { Button, ButtonProps } from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    content: React.ReactNode | string;
    onClick: (e: any) => void;
} & Pick<ButtonProps, 'dataTestId' | 'leftAddons' | 'rightAddons' | 'nowrap'>;

export const PickerOptionButton = ({ content, onClick, ...restButtonProps }: Props) => (
    <div className={ styles.option }>
        <Button block={ true } view="ghost" size="xs" onClick={ onClick } { ...restButtonProps }>
            { content }
        </Button>
    </div>
);
