import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Link, useLocation, useNavigate, useRoutes,
} from 'react-router-dom';
import {
    Menu, ModalProvider, NotFound, PageLoading,
} from '@alphakits/ui';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { PickerOptionButton } from 'src/components/picker-option-button';
import { useApi } from 'src/providers/api/context';
import { REGISTRATIONS } from 'src/router/constants';
import routes from 'src/router/routes';
import { DefaultPageProps } from 'src/types';

const Routes = ({ t }) =>
    useRoutes([
        ...routes(t),
        {
            path: '*',
            element: (
                <NotFound
                    title="OOOPS"
                    code="404"
                    buttonText="Вернуться на главную"
                    subtitle="Похоже, что такой страницы не существует"
                    href={ REGISTRATIONS }
                />
            ),
        },
        {
            path: '/',
            element: <React.Fragment />,
        },
    ]);

export const Root: React.FC<DefaultPageProps> = () => {
    const { admins } = useApi();
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    const redirect = useCallback(() => {
        window.location.assign(`${process.env.REACT_APP_AUTH_DOMAIN}/auth/admins`);
    }, []);

    const { meStatusOk, meFetch } = useApiMethod(admins.me, {
        successCallback: () => {
            if (location.pathname === '/') {
                navigate('/registrations');
            }
        },
        errorCallback: redirect,
    });

    useEffect(() => {
        meFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!meStatusOk) return <PageLoading />;

    return (
        <ModalProvider t={ t }>
            <Menu
                t={ t }
                profileTitle="Admin Panel"
                profileImage="/logo.png"
                userName="ME"
                routes={ routes(t) }
                navigate={ navigate }
                pathname={ location.pathname }
                userImage=""
                userDialogOptions={ [
                    {
                        key: 1,
                        content: (
                            <PickerOptionButton
                                content={ t('buttons.logout') }
                                onClick={ () => admins.logout(undefined).then(() => redirect()) }
                            />
                        ),
                    },
                ] }
                Link={ Link }
            >
                <Routes t={ t } />
            </Menu>
        </ModalProvider>
    );
};
