/* eslint-disable quote-props */
export const TRANSLATIONS_DE = {
    menu: {
        '/registrations': 'Register',
        '/profiles': 'Konten',
        '/articles': 'Artikel',
        '/payments': 'Zahlungen',
        '/verifications': 'Verifizierungen',
        '/reviews': 'Bewertungen',
        '/emails': 'E-Mails',
        '/cities': 'Städte',
        '/acceptance': 'Acceptance',
        '/moderation': 'Moderation',
        '/girls': 'Accepted Girls',
    },

    buttons: {
        logout: 'Beenden',
        create: 'Erstellen',
    },

    save: 'Speichern',
    cancel: 'Abbrechen',
    createdAt: 'Erstellungsdatum',
    lastAuth: 'Letzte Anmeldung',
    girlsCount: 'Fragebogen',
    saloonsCount: 'Salons',
    apartsCount: 'Wohnungen',
    balance: 'Guthaben',
    contact: 'Kontakt',
    title: 'Titel',
    amount: 'Betrag',
    profile: 'Profil',
    reason: 'Grund',
    name: 'Name',
    type: 'Typ',
    email: 'E-Mail',
    phone: 'Telefon',
    comment: 'Kommentar',
    status: 'Status',
    organizationProfile: 'Organisationskarte',
    verificatedComment: 'Identifizierte Verstöße',
    verificated: 'Profilanzeige zulassen',
    changed: 'Auf Aggregator angesehene Daten',
    text: 'Text',
    answer: 'Antwort',

    tables: {
        registrationsSearch: 'Suche nach ID, Name, E-Mail und Telefon',
        ariclesSearch: 'Nach Artikeltitel suchen',
        paymentsSearch: 'Suche nach Profil-ID und Betrag',
        organizationsSearch: 'Suche nach ID, Name, E-Mail und Telefon',
        reviewsSearch: 'Nach Rezensionstext suchen',
        verificatedSearch: 'Поиск по анкетам на модерации',
        createdSearch: 'Поиск по новым анкетам',
        girlsSearch: 'Поиск по анкетам',
        emailsSearch: 'Suche nach E-Mail',
        citiesSearch: 'Suche nach Stadtnamen',
    },

    suffix: {
        min: 'min',
        hour: 'h',
        people: 'Menschen',
    },

    error: {
        dataSuccessSaved: 'Alle Daten erfolgreich gespeichert',
        success: 'Erfolg',
        error: 'Fehler',
    },

    options: {
        profileTypes: {
            saloon: 'Salon',
            indie: 'Individuell',
            agenci: 'Agency',
        },
        balanceType: {
            payment: 'Zahlung',
            correction: 'Korrektur',
            charge: 'Tagesgebühr',
        },
        registrationStatus: {
            new: 'Neu',
            in_progress: 'Fortschritt',
            rejected: 'Storniert',
            success: 'Registrierung abgeschlossen',
        },
    },

    best: 'Mädchen des Monats',
    linkToAgregator: 'Link zum Aggregator:',
    privatePhoto: 'Private Fotos:',
    publicPhoto: ' öffentliche Fotos:',

    tags: 'Stichworte',
    fixed: 'Fest',
    profileId: 'Profil ID',
    delivered: 'Geliefert',
    saloonId: 'Salon ID',
    city: 'die Stadt',
    country: 'Land',

    photoUploaderTitle: 'Foto',
    photoUploaderSubtitle: 'Werden im Katalog angezeigt. Minimale Bildauflösung 1440 х 1220.',
};
