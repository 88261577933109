/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Loader, showError, Switch } from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { useApi } from 'src/providers/api/context';

type Props = {
    girlId: number;
    value: boolean;
    refetch: () => void;
};

export const SwitchBlocked: React.FC<Props> = ({ girlId, value, refetch }) => {
    const { girls } = useApi();

    const { toggleGirlBlockingFetch, toggleGirlBlockingLoading } = useApiMethod(
        girls.toggleGirlBlocking,
        {
            successCallback: refetch,
            errorCallback: (err) => showError(err.message),
        },
    );

    if (toggleGirlBlockingLoading) return <Loader />;

    return (
        <div onClick={ (e) => e.stopPropagation() }>
            <Switch
                checked={ value }
                onClick={ (e) => {
                    e.stopPropagation();
                } }
                onChange={ (e) => {
                    e.stopPropagation();
                    toggleGirlBlockingFetch(girlId);
                } }
            />
        </div>
    );
};
