import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { RegistrationRequestModal } from 'src/modals/registration-request';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { Columns } from './columns';

export const RegistrationsPage: React.FC<DefaultPageProps> = () => {
    const columns = React.useMemo(() => Columns(), []);

    const { openModal } = useContext(ModalContext);

    const openRequest = openModal(RegistrationRequestModal);

    const { registrationRequests } = useApi();

    return (
        <Table.TableComponent
            onRowClick={ ({ id }, refetch) => openRequest({ recordId: id }, refetch) }
            columns={ columns }
            searchPlaceholder="Search by e-mail or phone"
            language="en"
            promise={ registrationRequests.query }
        />
    );
};
