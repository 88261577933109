import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Root } from './pages/root';

import './locales/i18n';

function App() {
    return (
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    );
}

export default App;
