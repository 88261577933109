import React from 'react';
import { FormattedDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ModerationRO } from '@lovelyvoyager/types/dist/moderation/moderation.ro';
import { TFunction } from 'i18next';

export const Columns = (t: TFunction): TableColumns<ModerationRO> => [
    {
        header: t('name'),
        accessor: 'name',
        sort: true,
        row: {
            image: 'photo',
        },
    },
    {
        header: t('createdAt'),
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) => (
                <FormattedDate weight="medium" date={ row.updatedAt } format="dd.MM.yy, HH:mm" />
            ),
        },
    },
    {
        header: t('comment'),
        accessor: 'comment',
        sort: true,
    },
];
