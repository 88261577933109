import React from 'react';
import { Tag } from '@alphakits/ui';

type Props = {
    row: string;
    value: string;
    removeParam: (taggName: string) => void;
};

export const Admin: React.FC<Props> = ({ value, row, removeParam }) => {
    const text = `Ответственный: ${value}`;

    return <Tag text={ text } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />;
};
