import React from 'react';
import ReactDOM from 'react-dom';

import { ApiProvider } from './providers/api/context';
import App from './app';

import '@alphakits/ui/dist/index.css';
import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <ApiProvider>
            <App />
        </ApiProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
