import React from 'react';
import {
    AllertMarkM,
    ChecklistM,
    HeartFillM,
    LetterM,
    LocationM,
    MessageMarkM,
    PersonsTwoM,
} from 'alpha-cli-icons';
import { TFunction } from 'i18next';
import { AcceptancePage } from 'src/pages/acceptance';
import { CitiesPage } from 'src/pages/cities';
import { EmailsPage } from 'src/pages/emails';
import { GirlsPage } from 'src/pages/girls';
import { ModerationPage } from 'src/pages/moderation';
import { ProfilesPage } from 'src/pages/profiles';
import { RegistrationsPage } from 'src/pages/registrations';
import { ReviewsPage } from 'src/pages/reviews';

import {
    ACCEPTANCE,
    CITIES,
    EMAILS,
    GIRLS,
    MODERATION,
    PROFILES,
    REGISTRATIONS,
    REVIEWS,
} from './constants';

const routes = (t: TFunction) => [
    {
        path: REGISTRATIONS,
        element: <RegistrationsPage t={ t } />,
        icon: <ChecklistM />,
    },
    {
        path: PROFILES,
        element: <ProfilesPage t={ t } />,
        icon: <PersonsTwoM />,
    },
    {
        path: MODERATION,
        element: <ModerationPage />,
        icon: <AllertMarkM />,
    },
    {
        path: ACCEPTANCE,
        element: <AcceptancePage />,
        icon: <AllertMarkM />,
    },
    {
        path: GIRLS,
        element: <GirlsPage />,
        icon: <HeartFillM />,
    },
    // {
    //     path: ARTICLES,
    //     element: <ArticlesPage t={ t } />,
    //     icon: <ListOutlineM />,
    // },
    // {
    //     path: PAYMENTS,
    //     element: <PaymentsPage t={ t } />,
    //     icon: <MoneyStackM />,
    // },
    {
        path: REVIEWS,
        element: <ReviewsPage />,
        icon: <MessageMarkM />,
    },
    {
        path: EMAILS,
        element: <EmailsPage />,
        icon: <LetterM />,
    },
    {
        path: CITIES,
        element: <CitiesPage />,
        icon: <LocationM />,
    },
];

export default routes;
