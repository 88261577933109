import React from 'react';
import { FormattedDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { RegistrationRequestResponseObject } from '@lovelyvoyager/types/dist/registration-request/registration-request.ro';

export const Columns = (): TableColumns<RegistrationRequestResponseObject> => [
    {
        header: 'ID',
        sort: true,
        accessor: 'id',
        width: '60px',
    },
    {
        header: 'Created date',
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) => (
                <FormattedDate weight="medium" date={ row.createdAt } format="dd.MM.yy, HH:mm" />
            ),
        },
    },
    {
        header: 'Name',
        accessor: 'name',
    },
    {
        header: 'Phone',
        accessor: 'phone',
    },
    {
        header: 'Email',
        accessor: 'email',
    },
    {
        header: 'Status',
        accessor: 'status',
        sort: true,
    },
];
