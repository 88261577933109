import React from 'react';
import { FiltersMapper } from '@alphakits/ui/dist/table/types';
import { TFunction } from 'i18next';

import { Admin } from './admin';
import { Country } from './country';
import { FieldCount } from './field-count';
import { NotificationEnd } from './notification-end';
import { NotificationStart } from './notification-start';
import { RegisterEnd } from './register-end';
import { RegisterStart } from './register-start';
import { Status } from './status';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mapper =
    (t: TFunction) =>
        ({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
            paramName,
            paramValue,
            onDelete,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onClick,
        }: FiltersMapper) => {
            switch (paramName) {
                case 'registerFrom':
                    return <RegisterStart removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'registerTo':
                    return <RegisterEnd removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'notificationFrom':
                    return (
                        <NotificationStart removeParam={ onDelete } row={ paramName } value={ paramValue } />
                    );

                case 'notificationTo':
                    return (
                        <NotificationEnd removeParam={ onDelete } row={ paramName } value={ paramValue } />
                    );

                case 'admin':
                    return <Admin removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'status':
                    return <Status t={ t } removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                case 'questroomsCount':
                    return (
                        <FieldCount t={ t } removeParam={ onDelete } row={ paramName } value={ paramValue } />
                    );

                case 'locationsCount':
                    return (
                        <FieldCount t={ t } removeParam={ onDelete } row={ paramName } value={ paramValue } />
                    );

                case 'country':
                    return <Country t={ t } removeParam={ onDelete } row={ paramName } value={ paramValue } />;

                default:
                    return null;
            }
        };
