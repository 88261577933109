import React from 'react';
import { IconButton } from '@alphakits/ui/dist';
import { OpenS } from 'alpha-cli-icons';
import { useApi } from 'src/providers/api/context';

type Props = {
    profileId: number;
};

export const LoginAsUser = ({ profileId }: Props) => {
    const { admins } = useApi();

    const loginAsUser = async () => {
        try {
            await admins.getProfileToken(profileId);
            window.location.assign(`${process.env.REACT_APP_APP_DOMAIN}`);
        } catch (error) {
            alert('Error');
        }
    };

    return (
        <IconButton
            icon={ OpenS }
            onClick={ (e) => {
                e.stopPropagation();
                loginAsUser();
            } }
        />
    );
};
