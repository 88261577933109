import React from 'react';
import { Button, FormattedDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { GirlRO } from '@lovelyvoyager/types/dist/girl/girl.ro';
import { TFunction } from 'i18next';

import { SwitchAccept } from './switch-accept';

const DEFAULT_VALUE = '-';

export const Columns = (
    t: TFunction,
    openProfileModal: ({ recordId }, fetch: () => void) => void,
    openSaloonModal: ({ recordId }, fetch: () => void) => void,
): TableColumns<GirlRO> => [
    {
        header: t('id'),
        accessor: 'id',
        sort: true,
        width: '40px',
    },
    {
        header: t('name'),
        accessor: 'name',
        sort: true,
        row: {
            image: 'photo',
        },
    },
    {
        header: t('createdAt'),
        accessor: 'createdAt',
        sort: true,

        row: {
            title: ({ row }) => (
                <FormattedDate weight="medium" date={ row.updatedAt } format="dd.MM.yy, HH:mm" />
            ),
        },
    },
    {
        header: t('comment'),
        accessor: 'comment',
        sort: true,
    },
    {
        header: t('profileId'),
        accessor: 'profileId',
        sort: true,
        row: {
            title: ({ row, refetch }) => (
                <Button
                    size="xs"
                    onClick={ (e) => {
                        e.stopPropagation();
                        openProfileModal({ recordId: +row.id }, refetch);
                    } }
                >
                    { row.profileId || DEFAULT_VALUE }
                </Button>
            ),
        },
    },
    {
        header: t('saloonId'),
        accessor: 'saloonId',
        sort: true,
        row: {
            title: ({ row, refetch }) => (
                <Button
                    size="xs"
                    onClick={ (e) => {
                        e.stopPropagation();
                        openSaloonModal({ recordId: row.id }, refetch);
                    } }
                >
                    { row.saloonId || DEFAULT_VALUE }
                </Button>
            ),
        },
    },
    {
        header: 'Accept',
        accessor: 'accepted',
        sort: true,
        row: {
            title: ({ row, refetch }) => (
                <SwitchAccept refetch={ refetch } value={ row.accepted } girlId={ row.id } />
            ),
        },
    },
];
