/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import {
    Button,
    FlexColumns,
    showError,
    showSuccess,
    Textarea,
    useGetOne,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId?: number;
    close?: () => void;
    t: TFunction;
};

const fields = [
    'name',
    'indoorPrice',
    'outdoorPrice',
    'type',
    'address',
    'birthday',
    'height',
    'bustSize',
    'footSize',
    'weight',
    'phone',
    'about',
    'video',
    'whatsapp',
    'telegram',
    'bustType',
    'ethnicity',
    'orientation',
    'saloonId',
    'hairColor',
    'publicHair',
    'hairLength',
    'tattoo',
    'piercing',
    'smoker',
    'pornstar',
];

export const ModerationModal: React.FC<Props> = ({ recordId, close, t }) => {
    const { moderation } = useApi();

    const { record } = useGetOne(recordId, moderation.findOne);
    const [comment, setComment] = useState('');

    const { confirmFetch, confirmLoading } = useApiMethod(moderation.confirm, {
        successCallback: () => {
            showSuccess('Accepted');
            close();
        },
        errorCallback: () => showError('Error'),
    });

    const updateComment = async (comment, id) => {
        try {
            await moderation.updateComment({ id, data: { comment } });
            showSuccess('Comment saved');
            close();
        } catch (error) {
            showError('Error');
        }
    };

    useEffect(() => {
        setComment(record.comment);
    }, [record]);

    if (!record.id) return null;

    // const [deletedPhotos, addedPhotos] = [
    //     record.girl.photos.reduce(
    //         (acc, photo) => (record.photos.includes(photo) ? acc : [...acc, photo]),
    //         [],
    //     ),
    //     record.photos.reduce(
    //         (acc, photo) => (record.girl.photos.includes(photo) ? acc : [...acc, photo]),
    //         [],
    //     ),
    // ];

    const changes = fields.reduce((acc, key) => {
        if (record.girl?.[key] !== record[key]) {
            acc[key] = {
                old: record.girl[key],
                new: record[key],
            };
        }

        return acc;
    }, {});

    return (
        <div
            style={ {
                padding: 32,
                position: 'absolute',
                height: '100%',
                width: '100%',
                overflow: 'auto',
                top: 0,
                bottom: 0,
                boxSizing: 'border-box',
            } }
        >
            <a
                href={ `${process.env.REACT_APP_AGREGATOR_DOMAIN}/${record.girl?.slug}` }
                target="_blank"
                rel="noreferrer"
            >
                { t('linkToAgregator') }
            </a>

            <pre>{ JSON.stringify(changes, null, 4) }</pre>

            { /* { record.photo !== record.girl.photo && (
                <ImageGallery images={ [record.photo, record.girl.photo] } />
            ) }

            { !!deletedPhotos.length && <Gallery photos={ deletedPhotos } title="Removed photo" /> }
            { !!addedPhotos.length && <Gallery photos={ addedPhotos } title="New Photo" /> }

            <Gallery photos={ record?.photos || [] } title="All photos" /> */ }

            <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                <Textarea
                    dataTestId="verificatedComment"
                    label={ t('verificatedComment') }
                    value={ comment }
                    onChange={ (e) => setComment(e.target.value) }
                    block={ true }
                />

                <Button onClick={ () => updateComment(comment, record.id) }>Update comment</Button>

                <Button
                    loading={ confirmLoading }
                    view="primary"
                    onClick={ () => confirmFetch(record.id) }
                >
                    Accept changes
                </Button>
            </FlexColumns>
        </div>
    );
};
