import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui';
import { useApiMethod } from '@alphakits/utils/dist/hooks';
import { ModerationModal } from 'src/modals/moderation';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { Columns } from './columns';

export const ModerationPage: React.FC<DefaultPageProps> = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const { moderation } = useApi();

    const { findAllData, findAllFetch } = useApiMethod(moderation.findAll, {});

    useEffect(() => {
        findAllFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openGirlModal = openModal(ModerationModal, 'l');

    if (!findAllData) return null;

    return (
        <Table.TableComponent
            searchPlaceholder="Search by name"
            onRowClick={ ({ id }) => openGirlModal({ recordId: id }, () => findAllFetch(undefined)) }
            columns={ Columns(t) }
            records={ findAllData }
            refetch={ () => findAllFetch(undefined) }
            language="en"
        />
    );
};
