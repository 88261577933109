import React from 'react';
import {
    Divider,
    FlexColumns,
    InfoBlockItem,
    InfoBlockWrapper,
    Input,
    Select,
    Textarea,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { enumToOptions } from '@lovelyvoyager/services/dist/utils/enum-to-options';
import { CreateProfileDto } from '@lovelyvoyager/types/dist/profile/create-profile.dto';
import { ProfileTypeEnum } from '@lovelyvoyager/types/dist/profile/enum/profile-type.enum';
import { UpdateProfileDto } from '@lovelyvoyager/types/dist/profile/update-profile.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type OrganizationModalProps = {
    close: () => void;
    t: TFunction;
    recordId: number;
};

export const OrganizationModal = ({ close, t, recordId }: OrganizationModalProps) => {
    const { profiles } = useApi();

    const { record, loading, error } = useGetOne(recordId, profiles.getOne);

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: recordId ? profiles.update : profiles.create,
        removeRequest: profiles.remove,
        recordId,
        close,
    });

    return (
        <RestForm
            t={ t }
            loading={ loading }
            updating={ updating || removing }
            save={ save }
            error={ error }
            softError={ softError }
            recordId={ recordId }
            remove={ remove }
            validate={ validateByDto(t) }
            title={ t('organizationProfile') }
            close={ close }
            initialValues={ serializeRecord(recordId ? UpdateProfileDto : CreateProfileDto, record) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    { !!recordId && (
                        <InfoBlockWrapper view="bordered">
                            <InfoBlockItem label="ID" value={ recordId } />
                            <InfoBlockItem
                                label={ t('lastAuth') }
                                value={ record.lastAuth || 'never' }
                            />
                            <InfoBlockItem label={ t('balance') } value={ record.balance || 0 } />
                            <InfoBlockItem label={ t('type') } value={ record.type } />
                            <InfoBlockItem label={ t('girlsCount') } value={ record.girlsCount } />
                            <InfoBlockItem label={ t('saloonsCount') } value={ record.saloonsCount } />
                        </InfoBlockWrapper>
                    ) }
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        { !recordId && (
                            <Select
                                selected={ values.type }
                                label={ t('type') }
                                options={ enumToOptions(ProfileTypeEnum, t, 'profileTypes') }
                                onChange={ ({ selected }) => setFieldValue('type', selected.key) }
                            />
                        ) }
                        <Input
                            dataTestId="name"
                            label={ t('name') }
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && errors.name }
                            required={ true }
                            block={ true }
                        />
                        <Input
                            dataTestId="email"
                            label={ t('email') }
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && errors.email }
                            required={ true }
                            block={ true }
                        />
                        <Input
                            dataTestId="phone"
                            label={ t('phone') }
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            error={ touched.phone && errors.phone }
                            required={ true }
                            block={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <Textarea
                        dataTestId="comment"
                        label={ t('comment') }
                        value={ values.comment }
                        onChange={ handleChange('comment') }
                        block={ true }
                    />

                    <Divider size="l" />
                </React.Fragment>
            ) }
        </RestForm>
    );
};
