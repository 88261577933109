import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui';
import { GirlModal } from 'src/modals/girl';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const GirlsPage: React.FC<DefaultPageProps> = () => {
    const { t } = useTranslation();

    const { girls } = useApi();
    const { openModal } = useContext(ModalContext);

    return (
        <Table.TableComponent
            searchPlaceholder="Search by name"
            columns={ columns(t) }
            onRowClick={ ({ id }, refetch) =>
                openModal(GirlModal)({ recordId: id, saloons: [] }, refetch) }
            promise={ girls.getAllChangedGirls }
            language="en"
        />
    );
};
