import React from 'react';
import { FormattedDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ReviewRO } from '@lovelyvoyager/types/dist/review/review.ro';
import { TFunction } from 'i18next';

export const ReviewsColumns = (t: TFunction): TableColumns<ReviewRO> => [
    {
        header: t('createdAt'),
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) => (
                <FormattedDate weight="medium" date={ row.createdAt } format="dd.MM.yy, HH:mm" />
            ),
        },
    },
    {
        header: t('name'),
        accessor: 'name',
    },
    {
        header: t('email'),
        accessor: 'email',
    },
    {
        header: t('text'),
        accessor: 'text',
    },
    {
        header: t('answer'),
        accessor: 'answer',
    },
];
