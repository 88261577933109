import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CityResponseObject } from '@lovelyvoyager/types/dist/city/city.ro';
import { TFunction } from 'i18next';

export const Columns = (t: TFunction): TableColumns<CityResponseObject> => [
    {
        header: t('city'),
        accessor: 'title',
        sort: true,
    },
    {
        header: t('country'),
        accessor: 'country',
        sort: true,
    },
    {
        header: t('girlsCount'),
        accessor: 'girlsCount',
        sort: true,
    },
];
