import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { OrganizationModal } from 'src/modals/organization';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { сolumns } from './columns';

export const ProfilesPage: React.FC<DefaultPageProps> = ({ t }) => {
    const { openModal } = useContext(ModalContext);
    const { profiles } = useApi();

    const openOrganizationModal = openModal(OrganizationModal);

    return (
        <Table.TableComponent
            tagsBuilder={ mapper(t) }
            onRowClick={ (row, refetch) => openOrganizationModal({ recordId: row.id }, refetch) }
            onCreateClick={ (refetch) => openOrganizationModal(undefined, refetch) }
            columns={ сolumns() }
            searchPlaceholder="Search by e-mail, phone or ID"
            promise={ profiles.query }
            language="en"
        />
    );
};
