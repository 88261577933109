import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { CityModal } from 'src/modals/city';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { Columns } from './columns';

export const CitiesPage: React.FC<DefaultPageProps> = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const { cities } = useApi();

    const openGityModal = openModal(CityModal);

    return (
        <Table.TableComponent
            searchPlaceholder={ t('tables.citiesSearch') }
            onRowClick={ ({ id }, refetch) => openGityModal({ recordId: id }, refetch) }
            columns={ Columns(t) }
            promise={ cities.query }
            language="en"
        />
    );
};
