import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui';
import { GirlModal } from 'src/modals/girl';
import { GirlProfileModal } from 'src/modals/girl-profile';
import { GirlSaloonModal } from 'src/modals/girl-saloon';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { Columns } from './columns';

export const AcceptancePage: React.FC<DefaultPageProps> = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const { girls } = useApi();

    const openGirlModal = openModal(GirlModal);
    const openGirlProfileModal = openModal(GirlProfileModal);
    const openGirlSaloonModal = openModal(GirlSaloonModal);

    const columns = Columns(t, openGirlProfileModal, openGirlSaloonModal);

    return (
        <Table.TableComponent
            searchPlaceholder="Search by name"
            onRowClick={ (record, refetch) =>
                openGirlModal({ recordId: record.id, saloons: [] }, refetch) }
            columns={ columns }
            promise={ girls.getNotAccepted }
            language="en"
        />
    );
};
