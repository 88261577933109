import React from 'react';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { GirlRO } from '@lovelyvoyager/types/dist/girl/girl.ro';
import { TFunction } from 'i18next';
import { LoginAsUser } from 'src/components/login-as-user';

import { SwitchAccept } from '../acceptance/switch-accept';

import { SwitchBlocked } from './switch-blocked';

export const columns = (t: TFunction): TableColumns<GirlRO> => [
    {
        header: t('id'),
        accessor: 'id',
        sort: true,
    },
    {
        header: t('name'),
        accessor: 'name',
        sort: true,
        row: {
            image: 'photo',
        },
    },
    {
        header: t('profileId'),
        accessor: 'profileId',
        sort: true,
        row: {
            title: ({ row: { profileId } }) => (
                <React.Fragment>
                    { profileId } { !!profileId && <LoginAsUser profileId={ profileId } /> }
                </React.Fragment>
            ),
        },
    },
    {
        header: 'tags',
        accessor: 'tags',
        sort: true,
    },
    {
        header: 'top',
        accessor: 'best',
        sort: true,
    },
    {
        header: 'visible',
        accessor: 'visible',
        sort: true,
    },
    {
        header: 'ban',
        accessor: 'blocked',
        sort: true,
        row: {
            title: ({ row, refetch }) => (
                <SwitchBlocked refetch={ refetch } value={ row.blocked } girlId={ row.id } />
            ),
        },
    },
    {
        header: 'Accept',
        accessor: 'accepted',
        sort: true,
        row: {
            title: ({ row, refetch }) => (
                <SwitchAccept refetch={ refetch } value={ row.accepted } girlId={ row.id } />
            ),
        },
    },
];
