/* eslint-disable complexity */
import React from 'react';
import {
    FlexColumns,
    ImageUploader,
    Input,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { UpdateCityDto } from '@lovelyvoyager/types/dist/city/update-city.dto';
import { ImageTypeEnum } from '@lovelyvoyager/types/dist/upload/enum/image-type.enum';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId?: number;
    close?: () => void;
    t: TFunction;
};

export const CityModal: React.FC<Props> = ({ recordId, close, t }) => {
    const { cities, uploads } = useApi();

    const { loading, record, error } = useGetOne(recordId, cities.getOne);

    const {
        updating,
        save,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: cities.update,
        close,
    });

    return (
        <RestForm
            recordId={ recordId }
            title={ recordId ? record.title : t('cityForm') }
            initialValues={ serializeRecord(UpdateCityDto, {
                ...record,
            }) }
            validate={ validateByDto(t) }
            close={ close }
            save={ save }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            dataTestId="title"
                            label={ t('title') }
                            value={ values.title }
                            onChange={ handleChange('title') }
                            error={ touched.title && errors.title }
                            required={ true }
                            block={ true }
                        />

                        <ImageUploader
                            title={ `${t('photoUploaderTitle')}` }
                            subtitle={ t('photoUploaderSubtitle') }
                            value={ [values.photo] }
                            uploadRequest={ uploads.uploadImage }
                            onUpdate={ (value) => {
                                setFieldValue('photo', value);
                            } }
                            error={ touched.photo && errors.photo }
                            type={ ImageTypeEnum.CITY }
                            multiple={ false }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
