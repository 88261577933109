import React from 'react';
import {
    FlexColumns,
    InfoBlockItem,
    InfoBlockWrapper,
    Input,
    Select,
    Textarea,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { enumToOptions } from '@lovelyvoyager/services/dist/utils/enum-to-options';
import { RegistrationStatusEnum } from '@lovelyvoyager/types/dist/registration-request/enum/registration-status.enum';
import { UpdateRegistrationRequestDto } from '@lovelyvoyager/types/dist/registration-request/update-registration-request.dto';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type RequestModalProps = {
    close: () => void;
    t: TFunction;
    recordId: number;
};

export const RegistrationRequestModal = ({ close, t, recordId }: RequestModalProps) => {
    const { registrationRequests } = useApi();

    const { record, loading } = useGetOne(+recordId, registrationRequests.getOne);

    const { save, remove, updating } = useCrudFormRequests({
        saveRequest: registrationRequests.update,
        recordId,
        close,
    });

    return (
        <RestForm
            t={ t }
            loading={ loading }
            save={ save }
            updating={ updating }
            removing={ false }
            remove={ remove }
            validate={ validateByDto(t) }
            title={ `ID: ${record.id}` }
            close={ close }
            initialValues={
                serializeRecord(
                    UpdateRegistrationRequestDto,
                    record,
                ) as unknown as UpdateRegistrationRequestDto
            }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <InfoBlockWrapper view="bordered">
                        <InfoBlockItem label={ t('createdAt') } value={ record.createdAt } />
                    </InfoBlockWrapper>

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label={ t('name') }
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && (errors.name as string) }
                            required={ true }
                        />
                        <Input
                            label={ t('phone') }
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            error={ touched.phone && (errors.phone as string) }
                            required={ true }
                        />
                        <Input
                            label={ t('email') }
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && (errors.email as string) }
                            required={ true }
                        />
                        <Select
                            selected={ values.status }
                            label={ t('status') }
                            options={ enumToOptions(RegistrationStatusEnum, t, 'registrationStatus') }
                            onChange={ ({ selected }) => setFieldValue('status', selected.key) }
                        />
                        <Textarea
                            label={ t('comment') }
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
