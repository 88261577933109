/* eslint-disable complexity */
import React from 'react';
import {
    CalendarInput,
    Checkbox,
    convertToOptions,
    Divider,
    FlexColumns,
    formatDate,
    ImageUploader,
    Input,
    ListAllert,
    Select,
    SuperListHeader,
    Switch,
    Textarea,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { MIN_AGE } from '@lovelyvoyager/services/dist/constants';
import { serializeRecord, validateByDto } from '@lovelyvoyager/services/dist/utils';
import { enumToOptions } from '@lovelyvoyager/services/dist/utils/enum-to-options';
import { heights, weights } from '@lovelyvoyager/services/dist/utils/shared-options';
import { BustSizeEnum } from '@lovelyvoyager/types/dist/girl/enum/bust-size.enum';
import { BustTypeEnum } from '@lovelyvoyager/types/dist/girl/enum/bust-type.enum';
import { EthnicityEnum } from '@lovelyvoyager/types/dist/girl/enum/ethnicity.enum';
import { GirlLanguageEnum } from '@lovelyvoyager/types/dist/girl/enum/girl-language.enum';
import { GirlTypeEnum } from '@lovelyvoyager/types/dist/girl/enum/girl-type.enum';
import { HairColorEnum } from '@lovelyvoyager/types/dist/girl/enum/hair-color.enum';
import { HairLengthEnum } from '@lovelyvoyager/types/dist/girl/enum/hair-length.enum';
import { HairPublicEnum } from '@lovelyvoyager/types/dist/girl/enum/hair-public.enum';
import { OrientationEnum } from '@lovelyvoyager/types/dist/girl/enum/orientation.enum';
import { UpdateGirlByAdminDto } from '@lovelyvoyager/types/dist/girl/update-girl-by-admin.dto';
import { SaloonRO } from '@lovelyvoyager/types/dist/saloon/saloon.ro';
import { CustomTagsEnum } from '@lovelyvoyager/types/dist/shared/enum/tags.enum';
import { ImageTypeEnum } from '@lovelyvoyager/types/dist/upload/enum/image-type.enum';
import { parse, subYears } from 'date-fns';
import { TFunction } from 'i18next';
import { Video } from 'src/components/video';
import { useApi } from 'src/providers/api/context';

import 'plyr-react/plyr.css';

type Props = {
    recordId?: number;
    saloons: SaloonRO[];
    close: () => void;
    t: TFunction;
};

export const GirlModal: React.FC<Props> = ({
    recordId, saloons, close, t,
}) => {
    const { girls, uploads } = useApi();

    const { loading, record, error } = useGetOne(recordId, girls.getOneGirl);

    const {
        updating,
        removing,
        save,
        remove,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: girls.updateGirlByAdmin,
        removeRequest: girls.removeGirl,
        close,
    });

    return (
        <RestForm
            recordId={ recordId }
            title={ recordId ? record.name : t('girlForm') }
            initialValues={ serializeRecord(UpdateGirlByAdminDto, record) }
            validate={ validateByDto(t) }
            close={ close }
            remove={ remove }
            removing={ removing }
            save={ save }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <React.Fragment>
                    { record.moderation && <ListAllert text="Girl on moderation" /> }

                    <SuperListHeader text="Main info" />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            dataTestId="name"
                            label="Name"
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && errors.name }
                            required={ true }
                            block={ true }
                        />

                        { values.saloonId && (
                            <Select
                                selected={ values.saloonId }
                                label="Saloon"
                                options={ convertToOptions(saloons) }
                                onChange={ ({ selected }) => setFieldValue('saloonId', selected.key) }
                            />
                        ) }

                        { !values.saloonId && (
                            <React.Fragment>
                                <Input
                                    dataTestId="address"
                                    label="Address"
                                    value={ values.address }
                                    onChange={ handleChange('address') }
                                    error={ touched.address && errors.address }
                                    required={ true }
                                    block={ true }
                                />
                                <Input
                                    dataTestId="phone"
                                    label="Phone"
                                    value={ values.phone }
                                    onChange={ handleChange('phone') }
                                    error={ touched.phone && errors.phone }
                                    required={ !values.saloonId }
                                    block={ true }
                                />
                            </React.Fragment>
                        ) }

                        <Checkbox
                            dataTestId="whatsapp"
                            label="Whatsapp"
                            checked={ values.whatsapp }
                            onChange={ (e, { checked }) => setFieldValue('whatsapp', checked) }
                        />

                        <Input
                            dataTestId="telegram"
                            label="Telegram"
                            value={ values.telegram }
                            onChange={ handleChange('telegram') }
                            error={ touched.telegram && errors.telegram }
                            block={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <SuperListHeader text="Prices" />

                    <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                        <Input
                            dataTestId="outdoorPrice"
                            label="Outdoor price, € "
                            value={ `${values.outdoorPrice}` }
                            onChange={ handleChange('outdoorPrice') }
                            error={ touched.outdoorPrice && !!errors.outdoorPrice }
                            type="number"
                            block={ true }
                        />
                        <Input
                            dataTestId="indoorPrice"
                            label="Indoor price, € "
                            value={ `${values.indoorPrice}` }
                            onChange={ handleChange('indoorPrice') }
                            type="number"
                            error={ touched.indoorPrice && !!errors.indoorPrice }
                            block={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <SuperListHeader text="Params" />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 20 }>
                        <Select
                            selected={ values.type }
                            label="Type"
                            options={ enumToOptions(GirlTypeEnum, t) }
                            onChange={ ({ selected }) => setFieldValue('type', selected.key) }
                            error={ touched.type && errors.type }
                            required={ true }
                        />

                        <CalendarInput
                            calendarProps={ { t } }
                            value={ formatDate(
                                parse(values.birthday, 'yyyy-MM-dd', new Date()),
                                'dd-MM-yyyy',
                            ) }
                            label="Birthday"
                            onChange={ (e, { date }) => {
                                setFieldValue('birthday', formatDate(date, 'yyyy-MM-dd'));
                            } }
                            maxDate={ subYears(new Date(), MIN_AGE).getTime() }
                            block={ true }
                            required={ true }
                            error={ touched.birthday && errors.birthday }
                        />

                        <Select
                            selected={ values.ethnicity }
                            label="Ethnicity"
                            options={ enumToOptions(EthnicityEnum, t) }
                            onChange={ ({ selected }) => setFieldValue('ethnicity', selected.key) }
                            required={ true }
                            error={ touched.ethnicity && errors.ethnicity }
                        />

                        <Select
                            selected={ values.weight }
                            label="Weight"
                            options={ convertToOptions(weights) }
                            onChange={ ({ selected }) => setFieldValue('weight', selected.key) }
                            error={ touched.weight && errors.weight }
                            required={ true }
                        />

                        <Select
                            selected={ values.height }
                            label="Height"
                            options={ convertToOptions(heights) }
                            onChange={ ({ selected }) => setFieldValue('height', selected.key) }
                            error={ touched.height && errors.height }
                            required={ true }
                        />

                        <Select
                            selected={ values.bustSize }
                            label="Bust size"
                            options={ enumToOptions(BustSizeEnum, t, 'bustSize') }
                            onChange={ ({ selected }) => setFieldValue('bustSize', selected.key) }
                            error={ touched.bustSize && errors.bustSize }
                            required={ true }
                        />

                        <Select
                            selected={ values.bustType }
                            label="Bust type"
                            options={ enumToOptions(BustTypeEnum, t, 'bustType') }
                            onChange={ ({ selected }) => setFieldValue('bustType', selected.key) }
                            error={ touched.bustType && errors.bustType }
                            required={ true }
                        />

                        <Select
                            selected={ values.orientation }
                            label="Orientation"
                            options={ enumToOptions(OrientationEnum, t, 'orientation') }
                            onChange={ ({ selected }) => setFieldValue('orientation', selected.key) }
                            error={ touched.orientation && errors.orientation }
                            required={ true }
                        />

                        <Select
                            selected={ values.hairColor }
                            label="Hair color"
                            options={ enumToOptions(HairColorEnum, t, 'hairColor') }
                            onChange={ ({ selected }) => setFieldValue('hairColor', selected.key) }
                            error={ touched.hairColor && errors.hairColor }
                            required={ true }
                        />

                        <Select
                            selected={ values.hairLength }
                            label="Hair length"
                            options={ enumToOptions(HairLengthEnum, t, 'hairLength') }
                            onChange={ ({ selected }) => setFieldValue('hairLength', selected.key) }
                            error={ touched.hairLength && errors.hairLength }
                            required={ true }
                        />

                        <Select
                            selected={ values.publicHair }
                            label="Public hair"
                            options={ enumToOptions(HairPublicEnum, t, 'publicHair') }
                            onChange={ ({ selected }) => setFieldValue('publicHair', selected.key) }
                            error={ touched.publicHair && errors.publicHair }
                            required={ true }
                        />
                        <Select
                            selected={ values.languages }
                            label="Languages"
                            options={ enumToOptions(GirlLanguageEnum, t) }
                            onChange={ ({ selectedMultiple }) => {
                                setFieldValue(
                                    'languages',
                                    selectedMultiple.map((i) => i.key),
                                );
                            } }
                            error={ touched.languages && errors.languages?.toString() }
                            multiple={ true }
                            required={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />
                    <SuperListHeader text="Extra" />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 20 }>
                        <Switch
                            label="Tattoo"
                            onChange={ (_, { checked }) => setFieldValue('tattoo', checked) }
                            checked={ values.tattoo }
                        />

                        <Switch
                            label="Piercing"
                            onChange={ (_, { checked }) => setFieldValue('piercing', checked) }
                            checked={ values.piercing }
                        />

                        <Switch
                            label="Smoker"
                            onChange={ (_, { checked }) => setFieldValue('smoker', checked) }
                            checked={ values.smoker }
                        />

                        <Switch
                            label="Pornstar"
                            onChange={ (_, { checked }) => setFieldValue('pornstar', checked) }
                            checked={ values.pornstar }
                        />
                    </FlexColumns>

                    <Divider size="l" />
                    <SuperListHeader text={ t('params') } />

                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 20 }>
                        <Textarea
                            label="About"
                            value={ values.about }
                            onChange={ handleChange('about') }
                            error={ touched.about && errors.about }
                            required={ true }
                        />
                    </FlexColumns>

                    <Divider size="l" />

                    <ImageUploader
                        title="Photos *"
                        uploadRequest={ uploads.uploadImage }
                        value={ values.photos || [] }
                        main={ values.photo }
                        onUpdate={ (value) => {
                            if (!values.photo) {
                                setFieldValue('photo', value[0]);
                            }
                            setFieldValue('photos', value);
                        } }
                        setMain={ (value) => setFieldValue('photo', value) }
                        error={ touched.photo && errors.photo }
                        type={ ImageTypeEnum.GIRL }
                        multiple={ true }
                    />

                    <Divider size="l" />

                    <Video
                        onUpdate={ (value) => setFieldValue('video', value) }
                        value={ values.video }
                    />

                    <Divider size="l" />

                    <ImageUploader
                        title="Inside photos *"
                        uploadRequest={ uploads.uploadImage }
                        value={ values.verificationPhotos || [] }
                        onUpdate={ (value) => setFieldValue('verificationPhotos', value) }
                        error={ touched.verificationPhotos && (errors.verificationPhotos as string) }
                        type={ ImageTypeEnum.VERIFICATION }
                        multiple={ true }
                    />
                    <Divider size="l" />

                    <Select
                        dataTestId="tags"
                        block={ true }
                        multiple={ true }
                        optionsListWidth="field"
                        error={ touched.tags && (errors.tags as string) }
                        label="Tags"
                        popoverPosition="top"
                        options={ enumToOptions(CustomTagsEnum, t) }
                        selected={ values.tags }
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'tags',
                                selectedMultiple.map((tag) => tag.key),
                            ) }
                    />
                </React.Fragment>
            ) }
        </RestForm>
    );
};
