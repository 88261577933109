import React from 'react';
import { Tag } from '@alphakits/ui';
import { TFunction } from 'i18next';

type Props = {
    row: string;
    value: string;
    removeParam: (taggName: string) => void;
    t: TFunction;
};

export const FieldCount: React.FC<Props> = ({
    value, row, removeParam, t,
}) => {
    const text = `${t(row)}: ${value}`;

    return <Tag text={ text } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />;
};
