import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@alphakits/ui';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { Columns } from './columns';

export const EmailsPage: React.FC<DefaultPageProps> = () => {
    const { t } = useTranslation();
    const { emails } = useApi();

    return (
        <Table.TableComponent
            searchPlaceholder={ t('tables.emailsSearch') }
            columns={ Columns(t) }
            promise={ emails.query }
            language="en"
        />
    );
};
