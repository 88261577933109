export const REGISTRATIONS = '/registrations';
export const PROFILES = '/profiles';
export const ARTICLES = '/articles';
export const PAYMENTS = '/payments';
export const GIRLS = '/girls';
export const MODERATION = '/moderation';
export const REVIEWS = '/reviews';
export const EMAILS = '/emails';
export const CITIES = '/cities';
export const ACCEPTANCE = '/acceptance';
